import type { TRPCLink } from '@trpc/client';
import { observable } from '@trpc/server/observable';
import Cookies from 'js-cookie';

import { CookiesKeys } from '@monorepo/interfaces';

import type { AppRouter } from '../router';

export const unauthorizedLink: TRPCLink<AppRouter> = () => {
  return ({ next, op }) => {
    return observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          observer.next(value);
        },
        error(err) {
          observer.error(err);
          if (err?.data?.code === 'UNAUTHORIZED') {
            Cookies.set(
              CookiesKeys.LAST_PATH,
              encodeURI(window.location.pathname),
              {
                path: '/',
                domain: process.env.COOKIES_URL,
                httpOnly: false,
                maxAge: 360,
              }
            );
            window.open(`${process.env.MEU_ISAAC_URL}/auth`, '_self');
          }
        },
        complete() {
          observer.complete();
        },
      });
      return unsubscribe;
    });
  };
};
